import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type Tariffs = {
  allConsumerTariffsYaml: {
    edges: {
      node: {
        id: string;
        content: string;
      };
    }[];
  };
  allEnergyStructureYaml: {
    nodes: {
      id: string;
      content: string;
    }[];
  };
  allConsumerArchivalTariffsYaml: {
    edges: {
      node: {
        id: string;
        content: string;
      };
    }[];
  };
  allDiscussionOfDraftTariffsYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allConsumerTariffsYaml {
      edges {
        node {
          id
          content
        }
      }
    }
    allEnergyStructureYaml {
      nodes {
        id
        content
      }
    }
    allConsumerArchivalTariffsYaml {
      edges {
        node {
          id
          content 
        }
      }
    }
    allDiscussionOfDraftTariffsYaml {
      edges {
        node {
          content
          title
        }
      }
    }
  }
`;

const ConsumerTariffs: React.FC = () => {
  const data = useStaticQuery<Tariffs>(query);
  const consumerTariffs = data.allConsumerTariffsYaml.edges.map(({node}) => node);
  const energyStructure = data.allEnergyStructureYaml.nodes[0];
  const archivalConsumerTariffs = data.allConsumerArchivalTariffsYaml.edges.map(({node}) => node);
  const discussionOfDraftTariffs = data.allDiscussionOfDraftTariffsYaml.edges.map(({node}) => node);

  return (
    <Layout>
      <SEO title="Тарифи" />
      <Container className="general">
        <PageTitle title="Тарифи" />

        {consumerTariffs.map(item => (
          <ExpandableSection key={item.id} summary={item.id} innerHTML={item.content} />
        ))}
        <ExpandableSection key={energyStructure.id} summary={energyStructure.id} innerHTML={energyStructure.content} />

        <h2 className="title">Архівні тарифи</h2>
        {archivalConsumerTariffs.map(item => (
          <ExpandableSection key={item.id} summary={item.id} innerHTML={item.content} />
        ))}

        <h2 className="title">Обговорення проектів тарифів</h2>
        {discussionOfDraftTariffs.map(({title, content}) => (
          <ExpandableSection key={title} summary={title} innerHTML={content} />
        ))
        }
      </Container>
    </Layout>
  );
};

export default ConsumerTariffs;
